import React from 'react'
import { MoveRight } from 'lucide-react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useNavigate } from 'react-router-dom';
import MainSlider from "../MainSlider"

const pastas = [
  {
    id: 1,
    name: "Penne",
    desc: "PASTA",
    img: "./Img/PenneNew.png"
  },
  {
    id: 2,
    name: "Spring",
    desc: "PASTA",
    img: "./Img/SpringNew.png"
  },
  {
    id: 3,
    name: "Elbow",
    desc: "PASTA",
    img: "./Img/ElbowNew.png"
  },
  {
    id: 4,
    name: "Vermicelli",
    desc: "PASTA",
    img: "./Img/Vermicelli-New.png"
  },
  {
    id: 5,
    name: "Multi color",
    desc: "PASTA",
    img: "./Img/Multicolor.png"
  },


]
const fryums = [
  {
    id: 1,
    img: "./Img/FrumsNew.png",
    name: "Star Fryum",
    desc: "fryums"
  },
  {
    id: 2,
    img: "./Img/fryms2.png",
    name: "Flower Fryum",
    desc: "fryums"
  },
  {
    id: 3,
    img: "./Img/yellowfryms.png",
    name: "LongBhungra",
    desc: "fryums"
  },
  {
    id: 4,
    img: "./Img/Ring-Fryum.png",
    name: "Ring Fryum",
    desc: "fryums"
  },
  {
    id: 5,
    img: "./Img/PencilNew.png",
    name: "Pencil Fryum",
    desc: "fryums"
  },


]

const Allproducts = ({ setDataForSpecificProduct }) => {
  const navigation = useNavigate();
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  }



  function onProductClicked(e) {
    console.log(e);
    let a = e.desc.toLowerCase();
    console.log(a);
    switch (a) {
      case "fryums": {
        let dsrp = "Fryums are crunchy snacks made from flour dough. They come in various shapes and flavors. Fryums are deep-fried until crispy and are enjoyed as a tasty treat by many people.";

        setDataForSpecificProduct({ name: e.name, img: e.img, discription: dsrp });

        break;
      }
      case "pasta": {
        let dsrp = "Semolina pasta is made from durum wheat semolina flour. It's known for its firm texture and ability to hold sauces well. Common shapes include spaghetti, penne, and macaroni.";

        setDataForSpecificProduct({ name: e.name, img: e.img, discription: dsrp });

        break;
      }
      case "chunks": {
        let dsrp = "Soya chunks are small, chewy pieces made from soybeans. They're a high-protein meat substitute, often used in vegetarian dishes. They absorb flavors well and add texture to meals.";

        setDataForSpecificProduct({ name: e.name, img: e.img, discription: dsrp });

        break;
      }
      case "papad": {
        let dsrp = "Seviyan is a sweet Indian dish made from thin vermicelli noodles cooked in milk with sugar, cardamom, and sometimes nuts. It's a delicious dessert enjoyed during festivals and celebrations.";
        setDataForSpecificProduct({ name: e.name, img: e.img, discription: dsrp });

        break;
      }

      default:

        break;
    }
    navigation('/productdetails')

  }


  return (
    <>


      <div className="bg-[#F8EFEF]">
        <MainSlider />


        <div>
          <p className="text-[#574848] text-center font-bold  text-[25px]  md:text-[35px] lg:text-[46px] py-8">All Products</p>
        </div>


        {/* ////////soyabean chunks ////// */}
        {/* ////////For large Devices//// */}
        <div className="hidden md:grid  md:grid-cols-3  lg:grid-cols-6 justify-center items-center  cursor-pointer px-10 gap-x-8  my-4">

          <div className="flex flex-col items-center justify-center w-[270px] md:w-[220px] lg:w-[185px] h-[270px] rounded-[20px] bg-[#FFFFFF] ">
            <p className="text-[#574848] font-bold leading-[45.65px] text-[35px] ">Soyabean</p>
            <p className="text-[#B9625D] text-[30px]  leading-[38.04px] font-semibold py-2">Chunks</p>
            <MoveRight color='#B9625D' size={30} className="" />
          </div>



          <div onClick={() => onProductClicked({ desc: "chunks", img: "./Img/Soyachunks.png", name: "Soyabean" })} className=" w-[270px] md:w-[220px]  lg:w-[185px] h-[270px] rounded-[20px] bg-[#ffff] flex flex-col items-center justify-center  " >

            <div className="rounded-full bg-[#F8EFEF] border-[1px] border-[#B9625D] hover:border-none transition-transform duration-300 transform hover:scale-110 my-2 mx-2">
              <img src="./Img/Soyachunks.png" alt="soyabean" className="h-[130px] w-[130px] px-4 py-4" />
            </div>
            <p className="text-[26px] leading-[27.9px] tracking-widest font-semibold  text-[#574848] pt-4">Soyabean</p>
            <p className="text-[#B9625D] uppercase py-2 leading-[18.05px] tracking-wide">Chunks</p>



          </div>


        </div>
        {/* /////////For small Devices///// */}
        <div className="md:hidden flex flex-col gap-4 md:flex-row items-center justify-center mb-8 md:mt-0">

          <div className=" w-[77%] ">

            <div className="md:my-4 ">
              <Slider {...settings}>


                <div className=" w-[300px] h-[270px] rounded-[20px] bg-[#FFFFFF] text-center py-20   ">
                  <p className="text-[#574848]  font-bold text-[35px] ">SoyaBean</p>
                  <p className="text-[#B9625D]  text-[18px] md:text-[20px] tracking-wider">Chunks</p>
                  <MoveRight color='#B9625D' size={30} className="mx-auto" />
                </div>



                <div onClick={() => onProductClicked({ desc: "chunks", img: "./Img/Soyachunks.png", name: "Soyabean" })} className=" w-[300pxpx] md:w-[220px]  lg:w-[180px] h-[270px] rounded-[20px] bg-[#ffff] flex flex-col items-center justify-center  cursor-pointer ">

                  <div className="w-[150px] h-[150px] rounded-full border-[2px] border-[#B9625D] bg-[#F8EFEF] flex items-center justify-center  hover:border-none transition-transform duration-300 transform hover:scale-110  mt-10 mx-auto">
                    <img src="./Img/Soyachunks.png" alt="Soyachunks" className="h-[140px] w-[140px] px-4 py-4 object-cover" />
                  </div>
                  <p className="text-[26px] text-center  font-semibold tracking-widest text-[#574848] py-4">SoyaChunks</p>


                </div>




              </Slider>

            </div>

          </div>

        </div>


        {/*//// For large devices/// */}
        {/* //////// Pastas //////// */}
        <div className="hidden md:grid lg:grid-cols-6 md:grid-cols-3 lg:gap-y-0 lg:gap-x-6 md:gap-y-10  md:px-10  md:py-10 lg:py-4  ">

          <div className="flex flex-col items-center justify-center w-[270px] md:w-[220px] lg:w-[185px] h-[270px] rounded-[20px] bg-[#FFFFFF] ">
            <p className="text-[#574848] font-bold leading-[45.65px] text-[35px] ">Pastas</p>

            <MoveRight color='#B9625D' size={30} className="" />
          </div>

          {
            pastas.map((e, i) => {
              return <>

                <div onClick={() => onProductClicked(e)} className=" w-[270px] md:w-[220px]  lg:w-[185px] h-[270px] rounded-[20px] bg-[#ffff] flex flex-col items-center justify-center cursor-pointer ">

                  <div className="rounded-full bg-[#F8EFEF] border-[1px] border-[#B9625D] my-2  hover:border-none transition-transform duration-300 transform hover:scale-110 mx-2 w-[140px] h-[140px] flex  flex-col justify-center items-center   ">
                    <img src={e.img} alt="Pastas" className={`
                        ${e.id === 5 ? 'object-cover' : ''}
                    `}
                      style={e.id === 5
                        ? { height: '100px', width: '100px' }
                        : { height: '120px', width: '120px' }} />
                  </div>
                  <p className="text-[26px] leading-[27.9px] tracking-widest font-semibold  text-[#574848] pt-4">{e.name}</p>
                  <p className="text-[#B9625D] uppercase py-2 leading-[18.05px] tracking-wide pt-2">{e.desc}</p>


                </div>

              </>
            })
          }

        </div>
        {/* for small devices////// */}
        <div className="md:hidden flex items-center justify-center my-2 ">
          <div className=" w-[79%] ">

            <div className="md:my-4 ">
              <Slider {...settings} >

                <div className=" w-[300px] h-[270px] rounded-[20px] bg-[#FFFFFF] text-center py-20 ">
                  <p className="text-[#574848] font-bold text-[35px] ">Pasta</p>
                  <MoveRight color='#B9625D' size={30} className="mx-auto" />
                </div>

                {pastas.map((item) => {
                  return (
                    <>
                      <div onClick={() => onProductClicked(item)} className=" w-[300px] md:w-[220px]  lg:w-[180px] h-[270px] rounded-[20px] bg-[#ffff] flex flex-col items-center justify-center cursor-pointer  ">

                        <div className="rounded-full bg-[#F8EFEF] border-[2px] border-[#B9625D] flex items-center justify-center  hover:border-none transition-transform duration-300 transform hover:scale-110 my-2 w-[150px] h-[150px] mx-2">
                          <img src={item.img} alt="pasta-1" className="h-[120px] w-[120px] object-cover" />
                        </div>
                        <p className="text-[26px] text-center font-semibold tracking-widest text-[#574848]">{item.name}</p>
                        <p className="text-[#B9625D] uppercase text-center">{item.desc}</p>

                      </div>


                    </>
                  )


                })}
              </Slider>

            </div>

          </div>

        </div>



        {/* ///for large devices ////// */}
        {/*////// fryums////// */}
        <div className="hidden md:grid lg:grid-cols-6 md:grid-cols-3 lg:gap-y-0 lg:gap-x-6 md:gap-y-10  md:px-10  md:py-10 lg:py-4  ">

          <div className="flex flex-col items-center justify-center w-[270px] md:w-[220px] lg:w-[185px] h-[270px] rounded-[20px] bg-[#FFFFFF]">
            <p className="text-[#574848] font-bold leading-[45.65px] text-[35px] ">Fryums</p>
            <MoveRight color='#B9625D' size={30} className="" />
          </div>

          {
            fryums.map((e, i) => {
              return <>

                <div onClick={() => onProductClicked(e)} className=" w-[270px] md:w-[220px]  lg:w-[185px] h-[270px] rounded-[20px] bg-[#ffff] flex flex-col items-center justify-center cursor-pointer ">

                  <div className="rounded-full bg-[#F8EFEF] border-[1px] border-[#B9625D] my-2  hover:border-none transition-transform duration-300 transform hover:scale-110 mx-2 w-[140px] h-[140px] flex flex-col items-center justify-center">
                    <img src={e.img} alt="fryums" className={`
                        ${e.id === 2 || e.id === 1 ? 'object-cover' : ''}
                    `}
                      style={e.id === 2
                        ? { height: '90px', width: '90px' }
                        : { height: '120px', width: '120px' }} />
                  </div>
                  <p className="text-[24px] font-semibold tracking-wider pt-4 text-[#574848]">{e.name}</p>

                </div>

              </>
            })
          }

        </div>
        {/* /////for small devices */}
        <div className="md:hidden flex flex-col gap-4 md:flex-row items-center justify-center mt-8 md:mt-0">


          <div className=" w-[77%] ">

            <div className="md:my-4 ">
              <Slider {...settings}>


                <div className=" w-[300px] h-[270px] rounded-[20px] bg-[#FFFFFF] text-center py-20  ">
                  <p className="text-[#574848] font-bold text-[35px] ">Fryums</p>
                  <MoveRight color='#B9625D' size={30} className="mx-auto" />
                </div>

                {fryums.map((item) => {

                  return (


                    <>

                      <div onClick={() => onProductClicked(item)} className=" w-[300pxpx] md:w-[220px]  lg:w-[180px] h-[270px] rounded-[20px] bg-[#ffff] flex flex-col items-center justify-center  cursor-pointer ">

                        <div className="rounded-full border-[2px] border-[#B9625D] bg-[#F8EFEF] flex items-center justify-center  hover:border-none transition-transform duration-300 transform hover:scale-110  my-2 mx-2 w-[150px] h-[150px]">
                          <img src={item.img} alt="pasta-1" className={`
                        ${item.id === 2 || item.id === 1 ? 'object-cover' : ''}
                    `}
                            style={item.id === 2
                              ? { height: '90px', width: '90px' }
                              : { height: '120px', width: '120px' }} />
                        </div>
                        <p className="text-[26px] text-center font-semibold tracking-normal text-[#574848]">{item.name}</p>


                      </div>
                    </>
                  )


                })}
              </Slider>

            </div>

          </div>

        </div>



        {/* ///////papad//// */}
        {/* ///////Large devices//// */}
        <div className="hidden md:grid  md:grid-cols-3  lg:grid-cols-6 justify-center items-center px-10 cursor-pointer   gap-4  py-4">

          <div className="flex flex-col items-center justify-center w-[270px] md:w-[220px] lg:w-[185px] h-[270px] rounded-[20px] bg-[#ffff]  ">
            <p className="text-[#574848] font-bold text-[35px]">Papad</p>
            <MoveRight color='#B9625D' size={30} className="" />
          </div>

          <div className=" w-[270px] md:w-[220px]  lg:w-[185px] h-[270px] rounded-[20px] bg-[#ffff] flex flex-col items-center justify-center">

            <div className="rounded-full bg-[#F8EFEF] border-[1px] border-[#B9625D] my-2 mx-2 hover:border-none transition-transform duration-300 transform hover:scale-110 h-[140px] w-[140px] flex flex-col items-center justify-center ">
              <img src="./Img/Papad-New.png" alt="papad" className="h-[130px] w-[130px]  object-cover" />
            </div>
            <p className="text-[26px] font-semibold tracking-widest pt-4 text-[#574848]">Papad</p>

          </div>

        </div>
        {/* //////For small devices////// */}
        <div className="md:hidden flex flex-col gap-4 md:flex-row items-center justify-center cursor-pointer my-8">

          <div className=" w-[77%] ">

            <div className="md:my-4 ">
              <Slider {...settings}>


                <div className=" w-[300px] h-[270px] rounded-[20px] bg-[#FFFFFF] text-center py-20 ">
                  <p className="text-[#574848] font-bold text-[35px] ">Papad</p>
                  <MoveRight color='#B9625D' size={30} className="mx-auto" />
                </div>



                <div className=" w-[300pxpx] md:w-[220px]  lg:w-[180px] h-[270px] rounded-[20px] bg-[#ffff] flex flex-col items-center justify-center  cursor-pointer ">

                  <div className="w-[150px] h-[150px] rounded-full border-[2px] border-[#B9625D] bg-[#F8EFEF] hover:border-none transition-transform duration-300 transform hover:scale-110 mt-10 mx-auto flex flex-col items-center justify-center ">
                    <img src="./Img/Papad-New.png" alt="Papad" className="h-[140px] w-[140px] object-cover" />
                  </div>
                  <p className="text-[26px] text-center font-semibold tracking-normal text-[#574848] py-2">Papad</p>


                </div>




              </Slider>

            </div>

          </div>

        </div>


        {/* ///////Seviyan /////// */}
        {/* ////////For large Devices//// */}
        <div className="hidden md:grid  md:grid-cols-3  lg:grid-cols-6 justify-center items-center cursor-pointer px-10 pb-10  gap-4 my-4">

          <div className="flex flex-col items-center justify-center w-[270px] md:w-[220px] lg:w-[185px] h-[270px] rounded-[20px] bg-[#FFFFFF]">
            <p className="text-[#574848] font-bold text-[35px]">Seviyan</p>

            <MoveRight color='#B9625D' size={30} className="" />
          </div>



          <div onClick={() => onProductClicked({ desc: "papad", img: "./Img/Seviyan.png", name: "Seviyan" })} className=" w-[270px] md:w-[220px]  lg:w-[185px] h-[270px] rounded-[20px] bg-[#ffff] flex flex-col items-center justify-center  " >

            <div className="rounded-full bg-[#F8EFEF] border-[1px] border-[#B9625D] hover:border-none transition-transform duration-300 transform hover:scale-110 my-2 mx-2 h-[140px] w-[140px] flex items-center justify-center">
              <img src="./Img/Seviyan.png" alt="Seviyan" className="h-[100px] w-[100px]  object-cover " />
            </div>
            <p className="text-[26px] leading-[27.4px] pt-4 font-semibold tracking-widest text-[#574848]">Seviyan</p>




          </div>


        </div>
        {/* /////////For small Devices///// */}
        <div className="md:hidden flex flex-col gap-4 md:flex-row items-center justify-center pb-10 md:mt-0">

          <div className=" w-[77%] ">

            <div className="md:my-4 ">
              <Slider {...settings}>


                <div className=" w-[300px] h-[270px] rounded-[20px] bg-[#FFFFFF] text-center py-20 ">
                  <p className="text-[#574848] font-bold text-[35px] ">Seviyan</p>

                  <MoveRight color='#B9625D' size={30} className="mx-auto" />
                </div>



                <div onClick={() => onProductClicked({ desc: "papad", img: "./Img/Seviyan.png", name: "Seviyan" })} className=" w-[300pxpx] md:w-[220px]  lg:w-[180px] h-[270px] rounded-[20px] bg-[#ffff] flex flex-col items-center justify-center  cursor-pointer ">

                  <div className="w-[150px] h-[150px] rounded-full border-[2px] border-[#B9625D] bg-[#F8EFEF] flex items-center justify-center  hover:border-none transition-transform duration-300 transform hover:scale-110  mt-10 mx-auto">
                    <img src="./Img/Seviyan.png" alt="seviyan" className="h-[110px] w-[110px] object-cover" />
                  </div>
                  <p className="text-[26px] text-center font-semibold tracking-normal text-[#574848] py-4">Seviyan</p>


                </div>




              </Slider>

            </div>

          </div>

        </div>


      </div>



    </>
  )
}

export default Allproducts
