import React from 'react'
import { NavLink } from 'react-router-dom';

const Navbar = () => {
    return (
        <>
            <nav>

                <div className="hidden md:flex flex-row items-center md:justify-evenly lg:justify-between  ">

                    <div className=" md:px-6 lg:px-10  py-2 ">
                        <img src="./Img/logo.svg" alt="logo" />
                    </div>

                    <div className=" hidden md:flex flex-row justify-center items-center gap-4 lg:gap-10 text-[#101010] font-semibold text-[18px] leading-[22.8px] lg:px-12  ">
                        <NavLink to={"/"}>
                            <div><p>Home</p></div>
                        </NavLink>

                        <NavLink to={"/product"}>
                            <div><p>Products</p></div>
                        </NavLink>

                        <NavLink to={"/about"}>
                            <div><p>About us</p></div>
                        </NavLink>

                        <NavLink to={"/contact"}>
                            <div><p>Contact us</p></div>
                        </NavLink>

                    </div>
                </div>




                {/* ///////////For small devices///////// */}
                <div className="md:hidden flex flex-col ">
                    <div className="flex items-center justify-center">
                        <img src="./Img/logo.svg" alt="logo" />
                    </div>

                    
                    <div className=" md:hidden font-semibold flex gap-4 bg-[#B9625D] w-full text-[#FFFFFF] justify-center text-[18px] leading-[22.8px] py-2">
                        <NavLink to={"/"}>
                            <div><p>Home</p></div>
                        </NavLink>

                        <NavLink to={"/product"}>
                            <div><p>Products</p></div>
                        </NavLink>

                        <NavLink to={"/about"}>
                            <div><p>About us</p></div>
                        </NavLink>

                        <NavLink to={"/contact"}>
                            <div><p>Contact us</p></div>
                        </NavLink>

                    </div>
                </div>
            </nav>
        </>
    )
}

export default Navbar
