import React from 'react'
import MainSlider from "../MainSlider";
import {Link} from "react-router-dom";
const Herosection = () => {
    return (
        <>
            <MainSlider />
            <div className="bg-[#F8EFEF]">

                {/* ////////Products//////// */}
                <div className="py-10">
                    <p className="text-center text-[30px] md:text-[40px] text-[#574848] font-bold">Products</p>
                </div>

                {/* ///////Soyabean///////// */}
                <div className="lg:flex flex flex-col lg:flex-row  items-center justify-evenly  pt-16 gap-y-28 lg:gap-y-0  md:grid grid-cols-3 justify-items-center lg:px-0 md:px-4">

                    <Link to="/product" >
                    <div className=" bg-[#ffff] relative h-[200px] md:h-[180px] w-[250px] md:w-[200px] rounded-[20px] ">
                        <div>
                            <img src="./Img/Soyachunks.png" alt="soyabean" className="w-[150px] h-[150px] absolute left-0 right-0 mx-auto  top-[-64px] z-40 transition duration-300 ease-in-out transform hover:scale-110" />
                        </div>
                        <div className="flex flex-col items-center justify-center py-24">

                            <p className=" text-[25px] md:text-[30px] text-[#574848] font-bold">SoyaBean</p>
                            <p className="text-[#B9625D]  text-[18px] md:text-[20px] tracking-wider">Chunks</p>
                        </div>
                    </div>
                    </Link>


                    <Link to={"/product"}>
                    <div className=" bg-[#ffff] relative h-[180px] md:w-[200px]  w-[250px] rounded-[20px] ">
                        <div>
                            <img src="./Img/Seviyan.png" alt="Seviyaan" className="w-[180px] h-[230px] absolute mx-auto left-0 right-0 top-[-100px] z-40 transition duration-300 ease-in-out transform hover:scale-110" />
                        </div>
                        <div className="flex flex-col items-center justify-center py-24">

                            <p className=" text-[25px] md:text-[30px] text-[#574848] font-bold">Seviyan</p>
                        </div>
                    </div>
                    </Link>

                    <Link to={"/product"}>
                    <div className=" bg-[#ffff] relative  h-[180px]  md:w-[200px] w-[250px] rounded-[20px] ">
                        <div>
                            <img src="./Img/FryumNew.png" alt="soyabean" className="w-[180px] h-[160px] absolute left-0 right-0 mx-auto top-[-90px] z-40 transition duration-300 ease-in-out transform hover:scale-110" />
                        </div>
                        <div className="flex flex-col items-center justify-center py-24">

                            <p className=" text-[25px] md:text-[30px] text-[#574848] font-bold">Fryums</p>
                          
                        </div>
                    </div>
                    </Link>
                    

                    <Link to={"/product"}>
                    <div className=" bg-[#ffff] relative h-[180px] md:w-[200px] w-[250px] rounded-[20px] ">
                        <div>
                            <img src="./Img/Papad-New.png" alt="soyabean" className="w-[270px] h-[210px] absolute left-0 right-0 mx-auto top-[-100px] z-40 transition duration-300 ease-in-out transform hover:scale-110" />
                        </div>

                        <div className="flex flex-col items-center justify-center py-24">
                            <p className=" text-[25px] md:text-[30px] text-[#574848] font-bold">Papad</p>
                          
                        </div>
                    </div>
                    </Link>


                    <Link to={"/product"}>
                    <div className=" bg-[#ffff] relative  h-[180px] w-[250px] md:w-[200px] rounded-[20px] ">
                        <div>
                            <img src="./Img/PenneNew.png" alt="Penne" className="w-[270px] h-[180px] absolute left-0 right-0 mx-auto  top-[-75px] z-40 transition duration-300 ease-in-out transform hover:scale-110" />
                        </div>

                        <div className="flex flex-col items-center justify-center py-24">
                            <p className=" text-[25px] md:text-[30px] text-[#574848] font-bold">Pasta</p>
                            
                        </div>
                    </div>
                    </Link>


                </div>

                
                {/* ///////////Inquiry////////// */}
                <div className="py-8">
                    <p className="text-center text-[30px] md:text-[40px] text-[#574848] font-bold">Inquiry</p>
                </div>

            </div>


        </>
    )
}

export default Herosection
