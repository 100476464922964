import React, { useState } from 'react';
import './App.css';
import Footer from './Components/Footer';
import Home from './Components/Home/Home';
import Navbar from './Components/Navbar';
import About from './Components/About/About';
import Allproducts from './Components/Products/Allproducts';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Contact from './Components/Contactus/Contact';


import Soyabean from './Components/Soyabean';

function App() {

  const [dataForSpecificProduct, setDataForSpecificProduct] = useState({});


  return (
    <>
      <BrowserRouter>

        <Navbar />

        <Routes>
          <Route path='/'>
            <Route path='/' element={<Home />} />
          </Route>
          <Route exact path="/product" element={<Allproducts setDataForSpecificProduct={setDataForSpecificProduct}/>} />
          <Route exact path="/about" element={<About />} />
          <Route exact path="/contact" element={<Contact />} />
          <Route exact path="/productdetails" element={<Soyabean dataForSpecificProduct={dataForSpecificProduct} />} />
        </Routes>


        <Footer />
      </BrowserRouter>
    </>
  );
}

export default App;
