import React from 'react'
import { useState } from 'react';
const Inquiry = () => {
    const [result, setResult] = useState("");
    const onSubmit = async (event) => {
        event.preventDefault();
        setResult("Sending....");
        const formData = new FormData(event.target);

        formData.append("access_key", "762f80d4-ed96-48f7-8ee6-229cec6ca089");
        
        const response = await fetch("https://api.web3forms.com/submit", {
          method: "POST",
          body: formData
        });
    
        const data = await response.json();
    
        if (data.success) {
          setResult("Form Submitted Successfully");
          event.target.reset();
        } else {
          console.log("Error", data);
          setResult(data.message);
        }
      };
    return (
        <>
            <div>
                <form onSubmit={onSubmit}>

                    <div className="flex flex-col  lg:flex-row items-center justify-center lg:gap-16 md:gap-y-2 py-10">


                        <div>
                            <label for="name" className="block text-[#574848] font-bold text-[18px] pt-2">Name</label>
                            <input type="text" id="name" name="name" required  placeholder="Enter your name" className=" w-[320px] md:w-[412px] h-[40px] rounded-[6px] bg-[#EAEAEB80] focus-within:outline-none focus-within:border-[1px] border-[#B9625D] focus-within:bg-[#ffff] hover:border-[1px] hover:border-[#B9625D] hover:bg-[#ffff]  hover:shadow-[0px_4px_10px_0px_#BCBCBC40] transition-all duration-500  pl-6 my-2" />


                            <label for="Mobileno" className="block text-[#574848] font-bold text-[18px] pt-2">Mobile No</label>
                            <input type="tel" id="Mobileno" name="Mobileno" placeholder="+91 888888888" required  className=" w-[320px] md:w-[412px] h-[40px] rounded-[6px] bg-[#EAEAEB80] pl-6 my-2 focus-within:outline-none focus-within:border-[1px] border-[#B9625D] focus-within:bg-[#ffff] hover:border-[1px] hover:border-[#B9625D] hover:bg-[#ffff] hover:shadow-[0px_4px_10px_0px_#BCBCBC40] transition-all duration-500" />


                            <label for="Adress" className="block text-[#574848] font-bold text-[18px] pt-2">Address</label>
                            <input type="text" id="Adress" name="Adress" placeholder="Enter Address" required  className=" w-[320px] md:w-[412px] h-[40px] rounded-[6px] bg-[#EAEAEB80] pl-6 my-2 focus-within:outline-none focus-within:border-[1px] border-[#B9625D] focus-within:bg-[#ffff] hover:border-[1px] hover:border-[#B9625D] hover:bg-[#ffff] hover:shadow-[0px_4px_10px_0px_#BCBCBC40] transition-all duration-500" />
                        </div>


                        <div>
                            <label for="Product" className="block text-[#574848] font-bold text-[18px] pt-2">Select Products</label>
                            <input type="text" id="Product" name="Product" placeholder="Enter Required Product" required  className=" w-[320px] md:w-[412px] h-[40px] rounded-[6px] bg-[#EAEAEB80] focus-within:outline-none focus-within:border-[1px] border-[#B9625D] focus-within:bg-[#ffff] hover:border-[1px] hover:border-[#B9625D] hover:bg-[#ffff] hover:shadow-[0px_4px_10px_0px_#BCBCBC40]  transition-all duration-500 pl-6 my-2" />


                            <label for="quantity" className="block text-[#574848] font-bold text-[18px] pt-2">Quantity</label>
                            <input type='number' id="quantity" name="quantity" required placeholder="Enter Quantity"  className=" w-[320px] md:w-[412px] h-[40px] rounded-[6px] bg-[#EAEAEB80] pl-6 my-2 focus-within:outline-none focus-within:border-[1px] border-[#B9625D] focus-within:bg-[#ffff] hover:border-[1px] hover:border-[#B9625D] hover:bg-[#ffff] hover:shadow-[0px_4px_10px_0px_#BCBCBC40] transition-all duration-500" />


                            <label for="query" className="block text-[#574848] font-bold text-[18px] pt-2">Query</label>
                            <input type="text" id="query" name="query" placeholder="Write your query here"  required className=" w-[320px] 
                            md:w-[412px] h-[40px] rounded-[6px] bg-[#EAEAEB80] pl-6 my-2 focus-within:outline-none focus-within:border-[1px] border-[#B9625D] focus-within:bg-[#ffff] hover:border-[1px] hover:border-[#B9625D] hover:bg-[#ffff] hover:shadow-[0px_4px_10px_0px_#BCBCBC40] transition-all duration-500" />
                        </div>


                    </div>

                    <div className="flex items-center justify-center">

                    <button type="submit" className="bg-[#B9625D] text-[#ffff] font-semibold text-[20px] rounded-lg px-10 py-2 hover:border-[#B9625D] hover:border-[2px] hover:bg-[#ffff] hover:text-[#B9625D] transition-all duration-300 mb-10">Submit</button>
                    </div>
                </form>


                <hr class="h-2 w-[90%] mx-auto  border-[#574848] rounded-[1px] opacity-50  "></hr>
            </div>
        </>
    )
}

export default Inquiry
