import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'

const Soyabean = ({ dataForSpecificProduct }) => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])
    return (
        <>

            <div className="bg-[#F8EFEF]">

                <div className="px-10 py-10 flex flex-col lg:flex-row items-center justify-center gap-x-10">
                    <div className="h-[300px] md:h-[500px] w-[300px] md:w-[500px] bg-white rounded-[10px] border-[1px] boder-[#57484833]  flex items-center justify-center">
                        <img src={dataForSpecificProduct?.img} alt="soyabean" className="h-[220px] md:h-[320px] w-[220px] md:w-[336px] object-contain" />
                    </div>

                    <div className="w-[95%] md:w-[600px] py-6 lg:py-0">
                        <p className="text-[#B9625D] text-[28px] md:text-[37px] lg:text-[46px] font-bold  break-all ">{dataForSpecificProduct?.name}</p>
                        <p className=" text-[22px] md:text-[26px] leading-[35px] md:leading-[46px] text-[#574848] py-2">{dataForSpecificProduct?.discription}</p>
                    </div>
                </div>

               


                <div className="text-center text-[#B9625D] font-bold text-[22px] md:text-[26px] underline cursor-pointer py-8 ">
                <Link to={"/product"}>View All Products </Link>
                </div>






            </div>
        </>
    )
}

export default Soyabean
