
import React from 'react'

const About = () => {
  return (
    <>
      <div className="bg-[#F8EFEF]">
        <div>
          <p className="text-[#574848] text-[30px] md:text-[40px] lg:text-[46px] font-bold text-center py-10">About us</p>
        </div>




        <div className="flex flex-col-reverse lg:flex-row items-center justify-center gap-8 md:gap-14 px-8 pt-4">

          <div className="w-[94%] mx-auto md:w-[620px]">
            <p className="text-[21px] font-medium text-[#574848] break-normal leading-[38px]  ">Welcome to <span className="text-[#B9625D]">Kushal Brothers</span>, a pioneer in the food industry for the past <span className="text-[#B9625D]">30 years</span>, based in the vibrant city of Ahmedabad, Gujarat. We take pride in our expertise in crafting high-quality<span className="text-[#B9625D]"> soyabean chunks, vermicelli, pasta, and fryums</span> serving households with flavours that captivate and nourish.
            </p>
          </div>

          <div>
            <div className="w-[320px] md:w-[550px] h-[280px] md:h-[364px] border-[2px] rounded-[14px] border-[#574848] border-dashed relative">
              <img src="./Img/plant1.jpeg" alt="plant" className="w-[320px] md:w-[550px] h-[300px] md:h-[364px] border-[3px] border-[#B9625D] rounded-[14px] absolute left-4 bottom-4" />

            </div>
          </div>


        </div>


        <div className="flex flex-col lg:flex-row items-center justify-center gap-8 md:gap-14 px-8  py-14">

          <div>
            <div className=" w-[320px] md:w-[550px] h-[280px] md:h-[364px] border-[2px] rounded-[14px] border-[#574848] border-dashed relative">
              <img src="./Img/plant2.jpeg" alt="plant" className="w-[320px] md:w-[550px] h-[300px] md:h-[364px] border-[3px] border-[#B9625D] rounded-[14px] absolute left-4 bottom-4" />
            </div>
          </div>

          <div className="w-[94%] mx-auto  md:w-[602px]">
            <p className="text-[21px] text-[#574848] font-medium break-normal leading-[38px] ">Founded <span className="text-[#B9625D]">three decades ago</span>, our journey began with a vision to revolutionise the culinary experience by providing nutritious and delicious options to our customers. Through years of dedication and commitment, we have emerged as a trusted name, synonymous with innovation, quality, and taste.</p>
          </div>


        </div>


        <div className="flex flex-col-reverse lg:flex-row items-center gap-8 lg:gap-14 justify-center">
          <div className=" w-[94%] mx-auto md:w-[602px] text-[#574848] text-[21px]  break-normal font-medium px-6 md:px-6 leading-[38px]">
            <p>At Kushal Brothers, we prioritize excellence in every aspect of our operations. From sourcing the finest ingredients to employing state-of-the-art manufacturing processes, we ensure that each product meets the highest standards of quality and safety. Our relentless pursuit of perfection has earned us the loyalty of countless customers who rely on us for wholesome and satisfying meals.
            </p>
          </div>

          <div className=" w-[85%] md:w-[500px] h-[350px] md:h-[500px]  relative">

            <img src="./Img/SmallBeans.png" alt="beans" className=" rounded-full border-[10px] border-[#B9625D] shadow-[0px_4px_20px_0px_#00000040]  h-[240px] md:h-[345px] w-[240px] md:w-[345px] absolute top-[12%] md:top-[10%] left-[15%] md:left-[12%] z-40" />

            <div className=" w-[250px] md:w-[300px]  h-[250px] md:h-[300px] rounded-full border-[#574848] border-[2px] border-dashed absolute top-[27%] md:top-[35%] transform hover:rotate-45 hover:scale-110 transition duration-300 "></div>

            <div className="w-[250px] md:w-[300px] h-[250px] md:h-[300px] rounded-full border-[#574848] border-[2px] border-dashed absolute top-[-10%] md:top-0 left-[25%] md:left-[35%]  transform hover:rotate-45 hover:scale-110 transition duration-300 "></div>

          </div>

          <div>


          </div>
        </div>


        <div>
          <p className="text-[21px] text-[#574848] font-medium px-8 md:px-16 break-normal py-8 leading-[38px]">But our success story extends beyond just delivering superior products. We are deeply rooted in the community, fostering relationships built on trust and integrity. Our team of passionate professionals works tirelessly to cater to diverse tastes and preferences, enriching lives one meal at a time.</p>
        </div>

        <div>
          <p className="text-[#B9625D] text-[22px] font-semibold leading-[45px] tracking-wider  px-8 md:px-16">Thank you for choosing Kushal Brothers – where taste meets tradition, and quality knows no compromise.
          </p>
        </div>

        <div>
          <p className="text-[#574848] text-[30px] md:text-[40px] lg:text-[46px] font-bold text-center py-8">Inquiry</p>
        </div>




      </div>
    </>
  )
}

export default About
