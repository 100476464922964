import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination,Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

const slider = () => {
  return (
    <>
     <div className="w-full h-full mx-auto ">
          <Swiper
            spaceBetween={30}
            centeredSlides={true}
            pagination={
              {
                clickable: true
              }
            }
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            navigation={false}
            modules={[Pagination, Navigation,Autoplay]}
          >
            <SwiperSlide><img src="./Img/Posternew.svg" alt="slider" className="h-full w-full" /></SwiperSlide>
            <SwiperSlide><img src="./Img/Banner1.png" alt="slider" className="h-full w-full" /></SwiperSlide>
            <SwiperSlide><img src="./Img/Banner2.png" alt="slider" className=" h-full w-full" /></SwiperSlide>
           
          </Swiper>
        </div>
    </>
  )
}

export default slider
