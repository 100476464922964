import React from 'react'
import Herosection from './Herosection'


const Home = () => {
  return (
   <>
   <Herosection/>
  
   </>
  )
}

export default Home
