import React from 'react'
import { PhoneCall, Mail } from 'lucide-react';
import { FaGoogle, FaFacebookF, FaInstagram } from "react-icons/fa";
import { BsTwitterX } from "react-icons/bs";
import Inquiry from './Home/Inquiry';
import { Link } from "react-router-dom"
const Footer = () => {
    const links = [
        {
            id: 1,
            Text: "Home",
            path: "/",

        },
        {
            id: 2,
            Text: "Products",
            path: "/product"

        },
        {
            id: 3,
            Text: "About us",
            path: "/about"

        },
        {
            id: 4,
            Text: "Contact us",
            path: "/contact"

        }
    ]

    const handleLinkClick = () => {
        window.scrollTo(0, 0); // Scrolls to the top when a link is clicked
    };
    return (
        <footer>


            <Inquiry />

            {/* /////////////For large devices/////////// */}
            <div className="hidden lg:grid lg:grid-cols-3 lg:gap-10 justify-items-center items-center px-10 py-16">

                <div className="flex items-center justify-center bg-[#F8EFEF] w-[350px] h-[200px] rounded-[14px]">
                    <img src="./Img/logo.svg" alt="logo" />
                </div>

                <div className="flex flex-col items-center justify-center">

                    <div>
                        <ul className="flex flex-col list-none space-y-6">
                            {links.map((link) => {
                                return (
                                    <>

                                        <li key={link.id}>
                                            <Link to={link.path} className=" text-[20px] text-[#574848] leading-[22.36px]  font-extrabold hover:text-[#B9625D] hover:text-[21px] transition-all duration-500 " onClick={handleLinkClick}>{link.Text} </Link>
                                        </li>

                                    </>
                                )
                            })}
                        </ul>

                    </div>
                </div>


                <div className="">
                    <div className="pb-6">
                        <p className="text-[#B9625D] text-[20px]  font-bold">Address</p>
                        <address className="text-[#574848] pt-2 not-italic font-semibold">1420/61 Anand Bhuvan rd,<br /> Madhupura, Ahmedabad-380004</address>
                    </div>

                    <div className="">
                        <p className="text-[#B9625D] text-[20px] font-bold pb-2 pt-4" >Contact Us</p>


                        <div className="flex gap-x-4">
                            <img src="./Img/Calling.svg" alt="call" className="h-[30px] w-[30px]" />
                            <p className="text-[#574848] font-semibold">+91 9316016795, +91 9426078271</p>
                        </div>

                        <div className="flex gap-x-4 py-3">
                            <img src="./Img/Mail .svg" alt="mail" className="h-[30px] w-[30px]" />
                            <a href="mailto:kushalbrothersgj@gmail.com" className="text-[#574848] font-semibold">
                                kushalbrothersgj@gmail.com
                            </a>
                        </div>
                    </div>
                </div>
            </div>



            {/* //////////////for small devices///////// */}

            <div className="lg:hidden flex items-center justify-center py-10">
                <div className="flex items-center justify-center bg-[#F8EFEF] w-[350px] md:w-[412px] h-[200px] rounded-[14px]">
                    <img src="./Img/logo.svg" alt="logo" />
                </div>
            </div>

            <div className="lg:hidden flex flex-col  justify-center items-center gap-10  pb-8 mx-1">

                <div className="">
                    <div className="">
                        <p className="text-[#B9625D] text-[20px] font-bold pb-2">Address</p>
                        <address className="text-[#574848] py-1 not-italic font-semibold">1420/61 Anand Bhuvan rd,<br /> Madhupura, Ahmedabad-380004</address>
                    </div>

                    <div className="py-1">
                        <p className="text-[#B9625D] text-[20px] font-bold py-3" >Contact Us</p>

                        <div className="flex gap-2">
                            <img src="./Img/Calling.svg" alt="call" className="h-[25px] w-[25px]" />
                            <p className="text-[#574848] font-semibold">+91 9316016795,  +91 9426078271</p>
                        </div>

                        <div className="flex gap-2 py-2">
                            <img src="./Img/Mail .svg" alt="mail" className="h-[25px] w-[25px]" />
                            <a href="mailto:kushalbrothersgj@gmail.com" className="text-[#574848] font-semibold">
                                kushalbrothersgj@gmail.com
                            </a>
                        </div>
                    </div>
                </div>

                <div className="flex flex-row   ">

                    <div>
                        <ul className="flex flex-col  list-none space-y-6  md:space-y-8">
                            {links.map((link) => {
                                return (
                                    <>

                                        <li key={link.id}>
                                            <Link to={link.path} className="text-[15px] text-[#574848] font-extrabold hover:text-[#B9625D] hover:text-[20px]" onClick={handleLinkClick}>{link.Text} </Link>
                                        </li>

                                    </>
                                )
                            })}
                        </ul>

                    </div>

                </div>


                <div className="flex flex-row  gap-6 md:gap-8 ">

                    <Link to="https://www.instagram.com/kushal.brothers?igsh=d2xkb2tyaTZwYWNn" target="_blank">
                        <div className="h-[40px] w-[40px] rounded-full bg-[#574848] flex items-center justify-center">
                            <FaInstagram color='#ffff' size={15} className="transition duration-300 ease-in-out transform hover:rotate-180" />
                        </div>
                    </Link>


                    <Link to="https://www.facebook.com/profile.php?id=61559124459241&mibextid=ZbWKwL" target="_blank">
                        <div className="h-[40px] w-[40px] rounded-full bg-[#574848] flex items-center justify-center">
                            <FaFacebookF color='#ffff' size={15} className="transition duration-300 ease-in-out transform hover:rotate-180" />
                        </div>
                    </Link>


                    <Link to="https://g.co/kgs/zN6pRU4" target="_blank">
                        <div className="h-[40px] w-[40px] rounded-full bg-[#574848] flex items-center justify-center">
                            <FaGoogle color='#ffff' size={15} className="transition duration-300 ease-in-out transform hover:rotate-180" />
                        </div>
                    </Link>

                    <div className="h-[40px] w-[40px] rounded-full bg-[#574848] flex items-center justify-center">
                        <BsTwitterX color='#ffff' size={15} className="transition duration-300 ease-in-out transform hover:rotate-180" />
                    </div>
                </div>


            </div>


            <div className="bg-[#B9625D] py-4 flex flex-col  md:flex-row items-center  justify-evenly lg:justify-between px-6">

                <div>
                    <p className="text-[#FFFFFF] text-[12px] lg:text-[16px] leading-[25px] tracking-normal md:tracking-wider font-bold">© 2024 KUSHAL BROTHERS ALL RIGHTS RESERVED</p>
                </div>

                <div>
                    <p className=" text-[#FFFFFF] text-[12px] lg:text-[16px] leading-[25px] tracking-wider font-bold">Design & Develop by <Link to="https://pruthatek.com" target="_blank" className="underline">PRUTHATEK</Link></p>
                </div>


                <div className=" hidden lg:flex flex-row gap-2 md:gap-4 ">

                    <Link to="https://www.instagram.com/kushal.brothers?igsh=d2xkb2tyaTZwYWNn" target="_blank">
                        <div className="h-[40px] w-[40px] rounded-full bg-[#574848] flex items-center justify-center">
                            <FaInstagram color='#ffff' size={15} className="transition duration-300 ease-in-out transform hover:rotate-180" />
                        </div>
                    </Link>


                    <Link to="https://www.facebook.com/profile.php?id=61559124459241&mibextid=ZbWKwL" target="_blank">
                        <div className="h-[40px] w-[40px] rounded-full bg-[#574848] flex items-center justify-center">
                            <FaFacebookF color='#ffff' size={15} className="transition duration-300 ease-in-out transform hover:rotate-180" />
                        </div>
                    </Link>


                    <Link to="https://g.co/kgs/zN6pRU4" target="_blank">
                        <div className="h-[40px] w-[40px] rounded-full bg-[#574848] flex items-center justify-center">
                            <FaGoogle color='#ffff' size={15} className="transition duration-300 ease-in-out transform hover:rotate-180" />
                        </div>
                    </Link>

                    <div className="h-[40px] w-[40px] rounded-full bg-[#574848] flex items-center justify-center">
                        <BsTwitterX color='#ffff' size={15} className="transition duration-300 ease-in-out transform hover:rotate-180" />
                    </div>
                </div>
            </div>




        </footer>
    )
}

export default Footer
