import React from 'react'
import { PhoneCall, Mail } from 'lucide-react';

const Contact = () => {
  return (
    <>

      <div className="bg-[#F8EFEF]">


        <div>
          <p className="text-[#574848] text-[25px] md:text-[40px] lg:text-[46px] font-bold text-center py-8">We are Situated here</p>
        </div>
        <div className="">
          <div className="border-none  rounded-[14px] shadow-[0px_4px_24px_0px_#8C8C8C40] 4 lg:h-[650px] md:h-[550px] h-[350px] mx-8 md:mx-10 lg:mx-14 ">
            <iframe src="https://www.google.com/maps/embed?pb=!1m23!1m12!1m3!1d117491.19688830808!2d72.5087002956372!3d23.0384581604448!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m8!3e6!4m0!4m5!1s0x395e84408153022d%3A0x672a625265086ec0!2sAnand%20Bhavan%2C%20Opposite%20Madhupura%20Chowk%2C%20Madupura%20Road%2C%20Bardolpura%2C%20Madhupura%2C%20Ahmedabad%2C%20Gujarat%20380004!3m2!1d23.038479499999998!2d72.5911019!5e0!3m2!1sen!2sin!4v1715269246076!5m2!1sen!2sin" width="" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" className="w-full h-full focus:border-none  "></iframe>
          </div>

         
        </div>

        <div>
          <p className="text-[#574848] text-[25px] md:text-[40px] lg:text-[46px] font-bold text-center py-8">Inquiry</p>
        </div>
      </div>

    </>
  )
}

export default Contact
